.componentBase {
  color: var(--primary-color);
  background-color: var(--primary-background-color);
  padding: var(--size-40) var(--size-32);

  @media (--viewport-md) {
    padding: var(--size-50) var(--size-60);
  }

  & > .heading {
    margin-bottom: var(--size-24);

    @media (--viewport-md) {
      max-width: 400px;
      margin: 0 auto var(--size-32) auto;
    }
  }
}

.form {
  display: flex;
  gap: var(--size-24);
  flex-direction: column;

  & > .stepperLayout {
    margin-top: var(--size-20);
  }
}

.heading {
  text-align: center;

  & > .subheadingLayout {
    margin-bottom: var(--size-24);
  }

  & > .headingLayout {
    margin-bottom: var(--size-18);
  }
}

.componentThankYouMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--size-32);
  padding: var(--size-40) var(--size-32) 0;
  color: var(--color-white);
  background-image: var(--gradient, var(--gradient-gold));
  text-align: center;

  @media (--viewport-md) {
    padding: var(--size-50) var(--size-60) 0;
  }

  & > .subheadingLayout {
    margin-bottom: calc(-1 * var(--size-20));
  }

  & > .headingLayout {
    max-width: 20ch;
  }

  & > .textLayout {
    max-width: 50ch;
    margin-top: var(--size-20);
  }

  & > .breathingCirclesContainer {
    width: 100%;
    height: var(--size-170);
  }
}

.breathingCirclesContainer {
  position: relative;
  overflow: hidden;

  & > .breathingCircles {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}

.breathingCircles {
  aspect-ratio: 1;
}

.componentErrorMessage {
  padding: var(--size-24);
  background-color: var(--color-dark-800);
}
